import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown";
import SEO from "../components/seo";
import Layout from "../components/layout";
import "../scss/events-page.scss";
import 'uikit/dist/css/uikit.css'
import GetImgUrl from "../utils/customFunctions";

export const query = graphql`
  query eventsPagesQuery($id: Int) {
    strapiEvents(strapiId: { eq: $id }) {
      Components
      Title
      slug
      Date
      Header {
        LeftBgColor
        RightBgColor
        Title
        Subtitle
        id
        CTA
        CTASecondary
        URL
        URLSecondary
        Visible
        Background {
          localFile {
            publicURL
            absolutePath
            childImageSharp {
              fluid(quality: 90, maxWidth: 1900) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

const Events = ({ data }) => {
  var headerData = {
    headerTitle: data.strapiEvents.Header.Title
      ? data.strapiEvents.Header.Title
      : "",
    headerDescription: data.strapiEvents.Header.Subtitle
      ? data.strapiEvents.Header.Subtitle
      : "",
    headerCTAPrimaryLabel: data.strapiEvents.Header.CTA
      ? data.strapiEvents.Header.CTA
      : "",
    headerCTAPrimaryUrl: data.strapiEvents.Header.URL
      ? data.strapiEvents.Header.URL
      : "",
    headerCTASecondaryLabel: data.strapiEvents.Header.CTASecondary
      ? data.strapiEvents.Header.CTASecondary
      : "",
    headerCTASecondaryUrl: data.strapiEvents.Header.URLSecondary
      ? data.strapiEvents.Header.URLSecondary
      : "",
    headerBgImage:
      data.strapiEvents.Header.Background.localFile.childImageSharp.fluid,
      headerRightBgColor: data.strapiEvents.Header.RightBgColor ? data.strapiEvents.Header.RightBgColor : '',
    headerLeftBgColor: data.strapiEvents.Header.LeftBgColor ? data.strapiEvents.Header.LeftBgColor : '',
  };

 

  return (
    <div>
      <Layout
        pageData={headerData}
        headerShow={data.strapiEvents.Header.Visible}
        eventDate={data.strapiEvents.Date}
        shareOnSocial={true}
      >
        <SEO title={data.strapiEvents.Header.Title} />
        <div className="events-page">
          {data.strapiEvents.Components.map((item, index) => {
            return (
              <div key={index}>
              
                <ReactMarkdown children={item.Text} />

                {item.ImageCarousel?.Images ? (
                  <div className="event-page-image uk-height-medium uk-cover-container uk-margin-none">
                    <Img
                      fluid={
                        GetImgUrl(item.ImageCarousel.Images?.localFile___NODE)
                          .fluid
                      }
                      alt="image"
                    ></Img>
                  </div>
                ) : ""}

                {item.Links ? (
                  <Link to={item.Links.URL} className="event-page-link">
                    {item.Links.Link}
                  </Link>
                ) : ""}
              </div>
            );
          })}
        </div>
      </Layout>
    </div>
  );
};

export default Events;
